import { Button, Input } from '@finn/design-system';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ui-modules';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, Theme, useMediaQuery } from '@material-ui/core';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Checkbox from '~/components/FormElements/Checkbox';
import { LegalText } from '~/modules/legal/constants';
import { getLegalAllText } from '~/modules/legal/utils';

import { initialValues, integromatSubmit, validationSchema } from './config';
import GDPRDetails from './GDPRDetails';
import NotifySuccess from './NotifySuccess';
import { useStyles } from './styles';

const localeId = 'general.jobAutoWhitepaperModal';

type Props = {
  open: boolean;
  onClose: () => void;
};

const JobAutoWhitepaperModal: React.FC<Props> = ({ open, onClose }) => {
  const [submitError, setSubmitError] = useState<Boolean | null>(null);
  const classes = useStyles();

  const router = useRouter();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  const onModalClose = useCallback(() => {
    onClose();
    // timeout so that modal can close without resetting thank you message
    setTimeout(() => {
      setSubmitError(null);
    }, 1000);
  }, [onClose]);

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const i18n = useIntl();
  const formTitle = i18n.formatMessage({ id: `${localeId}.formTitle` });
  const formDescription = i18n.formatMessage({
    id: `${localeId}.formDescription`,
  });
  const formCtaLabel = i18n.formatMessage({ id: `${localeId}.formCtaLabel` });
  const formCancelLabel = i18n.formatMessage({
    id: `${localeId}.formCancelLabel`,
  });

  const gdprNote = getLegalAllText(
    i18n.locale,
    LegalText.JOBAUTO_WHITEPAPER_LEAD_FORM_GPDR_CHECKBOX
  );

  const submitHandler = useCallback(async () => {
    const formData = form.getValues();
    setSubmitError(null);
    try {
      const res = await integromatSubmit(formData);
      if (res.status >= 200 && res.status < 400) {
        setSubmitError(false);
        interactionTrackingEvent(
          TrackingEventName.JOB_AUTO_WHITEPAPER_FORM_SUBMITTED,
          {
            url: router.pathname,
          }
        );
      } else {
        setSubmitError(true);
      }
    } catch {
      setSubmitError(true);
    }
  }, [form]);

  return (
    <Dialog
      open={open}
      onClose={onModalClose}
      fullScreen={isMobile}
      maxWidth={false}
      PaperProps={{ classes: { root: classes.paperRoot } }}
    >
      {submitError === false ? (
        <NotifySuccess localeId={localeId} onClose={onClose} />
      ) : (
        <form onSubmit={form.handleSubmit(submitHandler)}>
          <h5 className="global-t5-semibold mb-4">{formTitle}</h5>
          <p className="body-16-light mb-8 px-2">{formDescription}</p>
          <div className="mb-6 flex flex-col gap-6">
            <div className={classes.relative}>
              <Controller
                control={form.control}
                name="firstName"
                render={({ field, fieldState }) => (
                  <>
                    <Input
                      type="text"
                      label={i18n.formatMessage({
                        id: 'general.jobAutoWhitepaperModal.firstName',
                      })}
                      {...field}
                      error={
                        !!fieldState.error &&
                        fieldState.isTouched &&
                        i18n.formatMessage({
                          id: fieldState.error.message,
                        })
                      }
                    />
                  </>
                )}
              />
            </div>
            <div className={classes.relative}>
              <Controller
                control={form.control}
                name="lastName"
                render={({ field, fieldState }) => (
                  <>
                    <Input
                      type="text"
                      label={i18n.formatMessage({
                        id: 'general.jobAutoWhitepaperModal.lastName',
                      })}
                      {...field}
                      error={
                        !!fieldState.error &&
                        fieldState.isTouched &&
                        i18n.formatMessage({
                          id: fieldState.error.message,
                        })
                      }
                    />
                  </>
                )}
              />
            </div>
            <div className={classes.relative}>
              <Controller
                control={form.control}
                name="email"
                render={({ field, fieldState }) => (
                  <>
                    <Input
                      type="text"
                      label={i18n.formatMessage({
                        id: 'general.jobAutoWhitepaperModal.email',
                      })}
                      {...field}
                      error={
                        !!fieldState.error &&
                        fieldState.isTouched &&
                        i18n.formatMessage({
                          id: fieldState.error.message,
                        })
                      }
                    />
                  </>
                )}
              />
            </div>
            <div className={classes.relative}>
              <Controller
                control={form.control}
                name="phone"
                render={({ field, fieldState }) => (
                  <>
                    <Input
                      type="text"
                      label={i18n.formatMessage({
                        id: 'general.jobAutoWhitepaperModal.phone',
                      })}
                      {...field}
                      error={
                        !!fieldState.error &&
                        fieldState.isTouched &&
                        i18n.formatMessage({
                          id: fieldState.error.message,
                        })
                      }
                    />
                  </>
                )}
              />
            </div>
            <div className={classes.relative}>
              <Controller
                control={form.control}
                name="company"
                render={({ field, fieldState }) => (
                  <>
                    <Input
                      type="text"
                      label={i18n.formatMessage({
                        id: 'general.jobAutoWhitepaperModal.company',
                      })}
                      {...field}
                      error={
                        !!fieldState.error &&
                        fieldState.isTouched &&
                        i18n.formatMessage({
                          id: fieldState.error.message,
                        })
                      }
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="text-left">
            <div className={classes.checkbox}>
              <Controller
                control={form.control}
                name="gdprConsent"
                render={({ field, fieldState }) => {
                  return (
                    <Checkbox
                      error={!!fieldState.error}
                      onCheckedChange={field.onChange}
                      label={gdprNote}
                      checked={field.value}
                    />
                  );
                }}
              />
            </div>
            <div className={classes.details}>
              <GDPRDetails />
            </div>
          </div>
          <div className={classes.space}>
            <Button type="submit" loading={form.formState.isSubmitting}>
              {formCtaLabel}
            </Button>
          </div>
          <div>
            <Button onClick={onModalClose} variant="secondaryWhite">
              {formCancelLabel}
            </Button>
          </div>
        </form>
      )}
    </Dialog>
  );
};

export default JobAutoWhitepaperModal;
